.product-item__img {
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
}

.product-item__img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: var(--color-blue);
    transition: 0.3s;
}

.product-item__title {
    font-size: 16px;
    transition: 0.3s;
    color: var(--color-black-black);
    font-weight: 500;
}

.product-item__title:hover {
    color: var(--color-blue);
}

.text-author {
    color: darkblue;
    font-size: 12px;
}

.all-item-prod {
    width: 100%;
}

.prod-title-price {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.prod-price {
    white-space: nowrap;
    color: darkorange;
}

@media (min-width: 1024px) {
    .product-item__title {
        font-size: 20px;
    }
}