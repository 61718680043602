html {
    font-family: 'Montserrat', sans-serif;
}

body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FFFFFF;
    overflow-x: hidden;
    transition: 0.5s;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: 0.3s;
}

img {
    /*width: 100%;*/
    height: auto;
    vertical-align: middle;
    max-width: 100%;
}

svg {
    vertical-align: middle;
}

a {
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
}

main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 30px 0;
    padding-top: 80px;
    flex: 1 0 auto
}

footer {
    flex: 0 0 auto;
}

button:focus,
input:focus,
select:focus,
textarea:focus {
    outline: none;
}

ul {
    list-style: none;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}


/*layout*/

.container {
    max-width: 1300px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}

.site-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

p {
    font-size: 16px;
    line-height: 170%;
}
/* 
@media (min-width: 425px) {
    main {
        padding-top: 50px;
    }
} */

@media (min-width: 768px) {
    p {
        font-size: 20px;
    }
}