.footer-body {
  padding: 15px 0;
  border-top: 1px solid #ff9c2b;
}

.footer-logo {
  text-align: center;
  max-width: 220px;
  margin: 0 auto 25px;
  display: block;
}
/* 
.footer__logo-mob {
 
} */

.footer-right__top {
  display: flex;
  align-items: center;
  padding-right: 25%;
  justify-content: space-around;
  margin-bottom: 10px;
}

.social-link {
  margin: 0 7px;
  width: 34px;
  height: 34px;
}

.social-link svg {
  max-width: 30px;
  width: 100%;
  height: 28px;
}

.footer-right__bottom ul li {
  text-align: center;
  padding: 0 10px;
}

.footer-right__bottom ul li a {
  position: relative;
  overflow: hidden;
  transition: clip-path 375ms ease;
}

.footer-right__bottom ul li a:hover::before {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.footer-right__bottom ul li a::before {
  position: absolute;
  content: attr(data-content);
  color: #ff9800;
  text-decoration: underline;
  clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
  transition: clip-path 375ms ease;
  box-sizing: border-box;
}

.links-footer__body {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  width: 100%;
}

.footer-right .lnk-gplay{
  max-width: 155px;
}

@media (min-width: 600px) {
  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .footer-right {
    width: 80%;
  }

  .footer-right__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .links-footer__body {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1024px) {
  .footer-right__bottom ul li {
    text-align: left;
  }
  .footer-right__bottom {
    justify-content: space-between;
  }
  .links-footer__body {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer-right__top {
    justify-content: space-between;
  }
  .gplay-block{
    width: 31%;
  }
}
