@keyframes ldio-r5t29llr5oj-1 {
  0% { top: 29.700000000000003px; height: 140.6px }
  50% { top: 63px; height: 74px }
  100% { top: 63px; height: 74px }
}
@keyframes ldio-r5t29llr5oj-2 {
  0% { top: 38.025000000000006px; height: 123.94999999999999px }
  50% { top: 63px; height: 74px }
  100% { top: 63px; height: 74px }
}
@keyframes ldio-r5t29llr5oj-3 {
  0% { top: 46.35px; height: 107.3px }
  50% { top: 63px; height: 74px }
  100% { top: 63px; height: 74px }
}
.ldio-r5t29llr5oj div { position: absolute; width: 22px }.ldio-r5t29llr5oj div:nth-child(1) {
  left: 39px;
  background: #ff9933;
  animation: ldio-r5t29llr5oj-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.37037037037037035s
}
.ldio-r5t29llr5oj div:nth-child(2) {
  left: 89px;
  background: #9933cc;
  animation: ldio-r5t29llr5oj-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.18518518518518517s
}
.ldio-r5t29llr5oj div:nth-child(3) {
  left: 139px;
  background: #660099;
  animation: ldio-r5t29llr5oj-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}
.parent {
  width: 100%;
  height: 100%;
  
  overflow: auto;
  white-space: nowrap;
  text-align: center;
  font-size: 0;
  
  
}
.parent:before {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
      content: '';
  }
.loadingio-spinner-pulse-s6x2jzg27kl {
  width: 200px;
  height: 200px;
  display: inline-block;
  white-space: normal;
    vertical-align: middle;
    text-align: left;
  overflow: hidden;
}
.ldio-r5t29llr5oj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-r5t29llr5oj div { box-sizing: content-box; }