.form-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.new-pass {
    position: relative;
}

.new-pass {
    margin-bottom: 10px;
    padding-bottom: 15px;
}

.titlePass {
    text-align: center;
    padding-top: 50px;
    margin-bottom: 20px;
}