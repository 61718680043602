:root {
  --color-gray: #888888;
  --color-light-gray: #B7B7B7;
  --color-blue: #126BFB;
  --color-white: #FFFFFF;
  --color-black: #4C4C4C;
  --color-black-black: #4C4C4C;
  --color-light-blue: #E7F0FF;
  --color-middle-blue: #E7F0FF;
  --color-large-blue: #A8C1EB;
  --color-orange: #FF9800;
}