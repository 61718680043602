.main-banner{
    width: 100%;
    height: 75vh;
    position: relative;
}

.main-banner iframe,
.main-banner video{
    position: absolute;
    min-height: 100%;
    width: 100vw;
    left: 0;
    top: 0;
}

.test{
    margin-top: 2em;
    width: 100%;
    position: relative;
}

.test::before{
    content: "";
    display: block;
    padding-top: 56.25%;
}

.t {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}