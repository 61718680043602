.sign-title {
    margin-bottom: 35px;
    font-size: 20px;
    text-align: center;
}

.makeStyles-paper-2 {
    width: 450px;
}

.body__sign-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 260px;
    width: 100%;
    margin: 0 auto 15px;
}

.sign-in__button button {
    height: 40px;
    width: 250px;
    max-width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
    white-space: nowrap;
}

.sign-in__button {
    width: 120px;
    margin: 0 5px 10px;
}

.form-modal {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.form-modal .MuiFormControl-root {
    width: 250px;
    margin-bottom: 15px;
}

.go-to-registr {
    text-align: center;
}

.go-to-registr a,
.go-to-registr span {
    color: #FF9800;
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
}

.pop-up__form-style {
    text-align: center;
    margin-bottom: 35px;
}

.block-form {
    text-align: center;
    margin-bottom: 15px;
}

.block-form .MuiFormControl-root {
    max-width: 275px;
    width: 100%;
}

.block-form__registration {
    position: relative;
    padding-bottom: 10px;
}

.text-reset {
    font-size: 12px;
    text-decoration: underline;
    margin-bottom: 20px;
}

.error-form {
    font-size: 12px;
    color: red;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
}

@media (min-width: 1024px) {
    .sign-title {
        font-size: 32px;
    }
}